import { FormEvent, useEffect, useState } from 'react'
import { atualizarCurriculo, atualizarFotoPerfil, dadosCandidato } from '../../services/bancoTalentos';
import { listarDadosCandidato } from '../../interfaces/bancoTalentos';
import { Button, Loader } from '../styles';
import Notificacoes from '../Notificacoes';
import fotoPerfil from "../../assets/img/fotoPerfil.png";
import editarIcon from '../../assets/img/editar.png'
import editarIconBranco from '../../assets/img/iconBrancoEditar.png'
import { telefoneMascara } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import './curriculo.css'

function Curriculo() {
  
  const navigate = useNavigate()

  const [candidato, setCandidato] = useState<listarDadosCandidato>()
  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(false)
  const [dadosSobreMim, setDadosSobreMim] = useState(candidato?.sobreMim || "")
  const [modalSobre, setModalSobre] = useState(false)
  const [modalDadosPessoais, setModalDadosPessoais] = useState(false)
  const [visivel, setVisivel] = useState(false)

  const [nomeCompleto, setNomeCompleto] = useState(candidato?.nomeCompleto || undefined)
  const [telefone, setTelefone] = useState(candidato?.telefone || "")
  const [profissao, setProfissao] = useState()
  const [dataNascimento, setDataNascimento] = useState("")
  const [rua, setRua] = useState()
  const [numero, setNumero] = useState()
  const [bairro, setBairro] = useState()
  const [complemento, setComplemento] = useState()
  const [estado, setEstado] = useState()
  const [cidade, setCidade] = useState()
  const [uf, setUf] = useState()
  const [notificacaoEmail, setNotificacaoEmail] = useState(candidato?.autorizaEmail)
  const [notificacaoWhatsapp, setNotificacaoWhatsapp] = useState(candidato?.autorizaWhatsapp)
  const [curriculoAtualizado, setCurriculoAtualizado] = useState(false)

  const [modalAvatar, setModalAvatar] = useState(false)
  const [novoAvatar, setNovoAvatar] = useState<File>()
  const [avatarAtual, setAvatarAtual] = useState("")
  const [avatarPreview, setAvatarPreview] = useState<string | undefined>(undefined);
  const [erroAtualizarAvatar, setErroAtualizarAvatar] = useState(false)

  const [campoTelefone, setCampoTelefone] = useState(false)
  const [campoNomeCompleto, setCampoNomeCompleto] = useState(false)

  const [situacaoCadastral, setSituacaoCadastral] = useState("Incompleto")

  const [btnSalvarNotificacao, setBtnSalvarNotificacao] = useState(false)

  const submitDadosModal = (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    atualizarDados(e)
    fecharModal(setModalDadosPessoais)
  }

  const atualizarDados = async (e: FormEvent) => {
    e.preventDefault()

    const situacaoCadastral = verificaSituacaoCompleto()

    const payload = {
      sobreMim: dadosSobreMim,
      nomeCompleto,
      telefone,
      profissao,
      dataNascimento,
      rua,
      numero: numero ? numero : null,
      bairro,
      complemento,
      estado,
      cidade,
      uf,
      notificacaoEmail,
      notificacaoWhatsapp,
      situacaoCadastral
    }

    try {
      const payloadFiltrado = Object.fromEntries(Object.entries(payload).filter(([chave, valor]) => valor !== undefined))
      const resposta = await atualizarCurriculo(payloadFiltrado)

      if (resposta.status === 200) {
        setDadosSobreMim(resposta.data.sobreMim)
        setCurriculoAtualizado(true)
        setProcessando(false)
        fecharModal(setModalSobre)
        return setTimeout(() => {
        setCurriculoAtualizado(false)
      }, 5000)
      }
    }


    catch (error: any) {
      setProcessando(false)

      if (error.response.status === 400) setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const listarDadosCandidato = async () => {

    try {

      const resposta = await dadosCandidato()
      setCandidato(resposta.data)
      setDadosSobreMim(resposta.data.sobreMim)
      formatarDataNascimento(resposta.data.dataNascimento)
      setEstado(resposta.data.estado)
      setCidade(resposta.data.cidade)
      setUf(resposta.data.uf)
      setRua(resposta.data.rua)
      setBairro(resposta.data.bairro)
      setNotificacaoEmail(resposta.data.autorizaEmail)
      setNotificacaoWhatsapp(resposta.data.autorizaWhatsapp)
      setSituacaoCadastral(resposta.data.situacaoCadastral)
    } catch (error) {

      setProcessando(false)
      setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const fecharModal = (setStateModal: (arg: any) => void) => {
    setStateModal(false)
    setVisivel(false)
  }

  const abrirModal = (setStateModal: (arg: any) => void) => {
    setStateModal(true)
  }

  const handleChange = (e: any, setState: (arg: any) => void) => {
    setState(e.target.value)
  }

  const mudarVisibilidadeModal = async () => {
    if (telefone) validarTelefone(telefone);
    if (nomeCompleto) validarNomeCompleto(nomeCompleto);

    setCampoTelefone(false)
    setVisivel(!visivel)
  }

  const formatarDataNascimento = async (dataNascimento: string) => {
    if (dataNascimento) {
      const data = dataNascimento.toString().split("-")

      const anoNascimento = data[0]
      const mesNascimento = data[1]
      const diaNascimento = data[2]

      setDataNascimento(`${anoNascimento}-${mesNascimento}-${diaNascimento}`)
    }
  }

  const validarTelefone = (telefone: string) => {
    const regex = /^(\([0-9]{2}\))\s([0-9]{1})?\.([0-9]{4})\s-\s([0-9]{4})/
    const telefoneValidado = regex.test(telefone)

    if (!telefoneValidado) {
      setCampoTelefone(true)
      return setTimeout(() => {
        setCampoTelefone(false)
      }, 5000)
    }
  }

  const validarNomeCompleto = (nomeCompleto: string) => {
    const regex = /[A-Za-z]+( [A-Za-z]+)+$/
    const nomeValidado = regex.test(nomeCompleto)

    if (!nomeValidado) {
      setCampoNomeCompleto(true)
      return setTimeout(() => {
        setCampoNomeCompleto(false)
      }, 5000)
    }
  }

  const alterarFotoPerfil = (e: FormEvent) => {
    e.preventDefault()

    const target = e.target as HTMLInputElement;
    const novaFoto = target.files as FileList;

    if (novaFoto && novaFoto.length > 0) setNovoAvatar(novaFoto[0]);
  }

  const enviarFotoPerfil = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    if (!novoAvatar) {
      setErroAtualizarAvatar(true)
      setProcessando(false)

      return setTimeout(() => {
        setErroAtualizarAvatar(false)
      }, 5000)
    }

    const payload = {
      avatar: novoAvatar
    }

    try {

      const resposta = await atualizarFotoPerfil(payload)

      if (resposta.status === 200) {
        localStorage.setItem("candidatoHgAvatar", `${resposta.data}`)
        setProcessando(false)
        setModalAvatar(false)
        return window.location.reload()
      }

    } catch (error) {
      setProcessando(false)
      setErroAtualizarAvatar(true)
      setErro(true)
      setModalAvatar(false)

      return setTimeout(() => {
        setErroAtualizarAvatar(false)
      }, 5000)
    }
  }

  const logout = () => {

    localStorage.removeItem('candidatoHgAvatar')
    document.cookie = 'authCandidato=; expires=true, 01 Jan 2024 00:00:00 GMT'

    return navigate('/portal-auth')
  }

  const handleChangeEstado = (e:any) => {
    const nomeEstado = e.target.value.split('-')
    setEstado(nomeEstado[0])
    setUf(nomeEstado[1])
  }

  const verificaSituacaoCompleto = () => {
    if (dadosSobreMim && dadosSobreMim.trim() !== "" && dataNascimento && avatarAtual && rua && bairro && cidade && estado) {
      return "Completo"
    } else {
      return "Incompleto"
    }
  }

  useEffect(() => {
    const fotoAvatarCandidato = () => {
      const candidatoAvatar = localStorage.getItem('candidatoHgAvatar')

      if (!candidatoAvatar) return setAvatarAtual(fotoPerfil)
      
      return setAvatarAtual(candidatoAvatar)
    }

    fotoAvatarCandidato()
  }, [])

  useEffect(() => {
    listarDadosCandidato()
  }, [])

  useEffect(() => {
    setTelefone(candidato?.telefone || "");
  }, [candidato])

  useEffect(() => {
    if (novoAvatar) {
      const avatarPreview = URL.createObjectURL(novoAvatar);
      setAvatarPreview(avatarPreview);
    } else {
      setAvatarPreview(undefined);
    }
  }, [novoAvatar])

  useEffect(() => {
    if (notificacaoEmail !== candidato?.autorizaEmail || notificacaoWhatsapp !== candidato?.autorizaWhatsapp) return setBtnSalvarNotificacao(true)
    setBtnSalvarNotificacao(false)
  }, [notificacaoEmail, notificacaoWhatsapp])

  return (
    <section className='bodyCurriculo'>
      {erro && <Notificacoes cor='erro' texto='Falha ao Processar a Solicitação' />}
      {campoTelefone && <Notificacoes cor='erro' titulo='Telefone no formato inválido.' texto="Numero de telefone inválido." />}
      {campoNomeCompleto && <Notificacoes cor='erro' titulo='Preenchimento Obrigatório' texto="O campo nome completo é inválido. Insira o Nome Completo." />}
      {erroAtualizarAvatar && <Notificacoes cor='erro' titulo='Foto de perfil.' texto="Não foi possivel atualizar sua foto de perfil." />}
      {curriculoAtualizado && <Notificacoes cor='sucesso' titulo='Curriculo Atualizado!' texto="Seu curriculo foi atualizado com sucesso." />}
      <section className='containerCurriculo flex-column'>
        <div className='div-header-foto'>
          <section className='headerCurriculo'>
            <span className='container-fotoPerfil left' onClick={() => abrirModal(setModalAvatar)}>
              <div className='container-mudar-fotoPerfil'>
                <img src={avatarAtual ? avatarAtual : fotoPerfil} alt="" className='fotoPerfil' />
                <div className='overlayFotoPerfil'>
                  <span>Mudar Foto</span>
                </div>
              </div>
            </span>
            <span className='container-header flex-column'>
              <span className='nome-candidato'>
                <span>
                  <h2 className='nomeCompletoCandidato'>{candidato && candidato.nomeCompleto}</h2>
                  <label>{candidato && candidato.profissao}</label>
                </span>
                <img src={editarIconBranco} alt="" className='iconEditarModal' onClick={() => abrirModal(setModalDadosPessoais)} />
              </span>
              <span className='area-contato'>
                <span>
                  <h3>Telefone:</h3>
                  <label>{candidato && candidato.telefone}</label>
                </span>
                <span>
                  <h3>E-mail</h3>
                  <label>{candidato && candidato.email}</label>
                </span>
              </span>
            </span>
          </section>
        </div>
        <section className='mainCurriculo flex-row'>
          <aside className='left hidden-responsive'>
            <hr />
            <span className='endereco'>
              <h3>Endereço</h3>
              <p>{candidato && (candidato.rua ? `${candidato.rua} ${candidato.numero ? candidato.numero : ""}` : "Adicione um endereço")}</p>
              <p>{candidato && candidato.complemento}</p>
              <p>{candidato && candidato.bairro}</p>
              <p>{candidato && candidato.cep}</p>
              <p>{candidato && (candidato.cidade && candidato.estado) ? `${candidato.cidade}/${candidato.estado}-${candidato.uf}` : ""}</p>
            </span>
            <hr />
            <span className='notificacoes'>
              <input type="checkbox" name="autorizaEmail" defaultChecked={notificacaoEmail && notificacaoEmail} onClick={() => setNotificacaoEmail(!notificacaoEmail)} />
              <label htmlFor="autorizaEmail">Receber notificações por E-mails</label>
            </span>
            <span className='notificacoes'>
              <input type="checkbox" name="autorizaEmail" defaultChecked={notificacaoWhatsapp && notificacaoWhatsapp} onClick={() => setNotificacaoWhatsapp(!notificacaoWhatsapp)} />
              <label htmlFor="autorizaEmail">Receber notificações por Whatsapp</label>
            </span>
            <span>
              {btnSalvarNotificacao && <Button isLoading={processando} cor="continuarSucesso" type='button' isCancelar={false} onClick={(e) => atualizarDados(e)}>Salvar</Button>}
            </span>
            <hr />
            <span>
              {situacaoCadastral === "Incompleto" &&
                <label className="situacaoIncompleto">
                  <abbr title="O Sobre Mim, Data de Nascimento, Foto de Perfil e Endereço precisam ser preenchidos para completar o perfil.">
                    Perfil Incompleto
                  </abbr>
                </label>
              }
              {situacaoCadastral === "Completo" &&
                <label className="situacaoCompleto">
                  Perfil Completo
                </label>
              }
            </span>
            <Button isLoading={processando} cor="cancelar" type='button' isCancelar={false} onClick={() => logout()}>Logout</Button>
          </aside>
          <div className='main-content-curriculo'>
            <section className='sessao-inf-usuario'>
              <div className='curriculoAbout'>
                <div className='container-editar'>
                  <h2>SOBRE MIM</h2>
                  <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => setModalSobre(true)} />
                </div>
                <p>{candidato?.sobreMim !== "" ? candidato?.sobreMim : "Fale um pouco sobre você"}</p>
              </div>
            </section>
          </div>
        </section>
      </section>

      {modalSobre &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={submitDadosModal}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Sobre mim</h1>
                </span>
              </section>
              <section className="sectionModal">
                <textarea name="sobreMim" rows={15} placeholder='Fale um pouco sobre você' className='textAreaSobreMim' onChange={(e) => { setDadosSobreMim(e.target.value) }} maxLength={500} defaultValue={candidato?.sobreMim}></textarea>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => fecharModal(setModalSobre)}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modalDadosPessoais &&
        <div className='fundoModal'>
          <section className={`containerModalCentro ${!visivel ? '' : 'hide'}`}>
            <form className="formModal">
              <section>
                <span className="novoContainerTitulo">
                  <h1>Dados Pessoais</h1>
                </span>
              </section>
              <section className="sectionModal">
                <span>
                  <label htmlFor="">Nome Completo</label>
                  <input type="text" name="" defaultValue={candidato && candidato.nomeCompleto} placeholder='Digite seu Nome Completo' onChange={(e) => setNomeCompleto(e.target.value)} required />
                </span>
                <span>
                  <label htmlFor="">E-mail</label>
                  <input type="email" name="" defaultValue={candidato && candidato.email} placeholder='Preencha com seu E-mail' disabled />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Telefone</label>
                  <input type="text" value={telefone}
                    minLength={18}
                    maxLength={18}
                    placeholder='(XX) X.XXXX - XXXX'
                    onChange={e => setTelefone(telefoneMascara(e.target.value))} required />
                </span>
                <span>
                  <label htmlFor="">Profissão</label>
                  <input type="text" name="" defaultValue={candidato && candidato.profissao} placeholder='Adicione sua Profissão' onChange={(e) => handleChange(e, setProfissao)} />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Data Nascimento</label>
                  <input type="date" name="" defaultValue={dataNascimento} onChange={(e) => handleChange(e, setDataNascimento)} />
                </span>
                <span></span>
              </section>

              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => fecharModal(setModalDadosPessoais)}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='button' isCancelar={false} onClick={() => mudarVisibilidadeModal()}>
                  {!processando && 'Continuar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
          {/* Endereço */}
          <section className={`containerModalCentro ${visivel ? '' : 'hide'}`}>
            <form className="formModal" onSubmit={submitDadosModal}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Endereço</h1>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Rua</label>
                  <input type="text" name="" defaultValue={candidato && candidato.rua} placeholder='Digite o Nome da Rua' onChange={(e) => handleChange(e, setRua)} />
                </span>
                <span>
                  <label htmlFor="">Numero</label>
                  <input type="number" name="" defaultValue={candidato && candidato.numero} placeholder='Digite o Numero da Rua' onChange={(e) => handleChange(e, setNumero)} />
                </span>
              </section>

              <section>
                <span>
                  <label htmlFor="">Bairro</label>
                  <input type="text" name="" defaultValue={candidato && candidato.bairro} placeholder='Digite o Nome do Bairro' onChange={(e) => handleChange(e, setBairro)} />
                </span>
                <span>
                  <label htmlFor="">Complemento</label>
                  <input type="text" name="" defaultValue={candidato && candidato.complemento} placeholder='Digite um Complemento' onChange={(e) => handleChange(e, setComplemento)} />
                </span>
              </section>

              <section>
                <span>
                  <label htmlFor="estados">Estado</label>
                  <select value={`${estado}-${uf}`} onChange={(e) => handleChangeEstado(e)}>
                    <option value="Acre-AC">Acre</option>
                    <option value="Alagoas-AL">Alagoas</option>
                    <option value="Amapá-AP">Amapá</option>
                    <option value="Amazonas-AM">Amazonas</option>
                    <option value="Bahia-BA">Bahia</option>
                    <option value="Ceará-CE">Ceará</option>
                    <option value="Distrito Federal-DF">Distrito Federal</option>
                    <option value="Espírito Santo-ES">Espírito Santo</option>
                    <option value="Goiás-GO">Goiás</option>
                    <option value="Maranhão-MA">Maranhão</option>
                    <option value="Mato Grosso-MT">Mato Grosso</option>
                    <option value="Mato Grosso do Sul-MS">Mato Grosso do Sul</option>
                    <option value="Minas Gerais-MG">Minas Gerais</option>
                    <option value="Pará-PA">Pará</option>
                    <option value="Paraíba-PB">Paraíba</option>
                    <option value="Paraná-PR">Paraná</option>
                    <option value="Pernambuco-PE">Pernambuco</option>
                    <option value="Piauí-PI">Piauí</option>
                    <option value="Rio de Janeiro-RJ">Rio de Janeiro</option>
                    <option value="Rio Grande do Norte-RN">Rio Grande do Norte</option>
                    <option value="Rio Grande do Sul-RS">Rio Grande do Sul</option>
                    <option value="Rondônia-RO">Rondônia</option>
                    <option value="Roraima-RR">Roraima</option>
                    <option value="Santa Catarina-SC">Santa Catarina</option>
                    <option value="São Paulo-SP">São Paulo</option>
                    <option value="Sergipe-SE">Sergipe</option>
                    <option value="Tocantins-TO">Tocantins</option>
                  </select>
                </span>
                <span>
                  <label htmlFor="">Cidade</label>
                  <input type="text" name="" defaultValue={candidato && candidato.cidade} placeholder='Digite a Cidade onde vive' onChange={(e) => handleChange(e, setCidade)} />
                </span>
              </section>

              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={false} onClick={() => setVisivel(false)}>Voltar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
      {modalAvatar &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={enviarFotoPerfil}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Atualizar foto de perfil</h1>
                </span>
              </section>
              <section>
                <span>
                  <div className='container-mudar-fotoPerfil'>
                    <img src={avatarPreview ? avatarPreview : avatarAtual} alt="" className='fotoPerfil' />
                  </div>
                  <label htmlFor="">Selecione uma imagem</label>
                  <input type="file" name="fotoPerfil" accept='image/*' onChange={alterarFotoPerfil} />
                </span>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={() => fecharModal(setModalAvatar)}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
    </section>
  )
}

export default Curriculo